exports.components = {
  "component---src-pages-about-get-connected-js": () => import("./../../../src/pages/about/get-connected.js" /* webpackChunkName: "component---src-pages-about-get-connected-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-life-ag-js": () => import("./../../../src/pages/about/life@ag.js" /* webpackChunkName: "component---src-pages-about-life-ag-js" */),
  "component---src-pages-about-pastoral-team-js": () => import("./../../../src/pages/about/pastoral-team.js" /* webpackChunkName: "component---src-pages-about-pastoral-team-js" */),
  "component---src-pages-about-timings-js": () => import("./../../../src/pages/about/timings.js" /* webpackChunkName: "component---src-pages-about-timings-js" */),
  "component---src-pages-announcement-js": () => import("./../../../src/pages/announcement/[...].js" /* webpackChunkName: "component---src-pages-announcement-js" */),
  "component---src-pages-announcements-index-js": () => import("./../../../src/pages/announcements/index.js" /* webpackChunkName: "component---src-pages-announcements-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event/[...].js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-flag-admin-js": () => import("./../../../src/pages/flag-admin/[...].js" /* webpackChunkName: "component---src-pages-flag-admin-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-know-god-js": () => import("./../../../src/pages/know_God.js" /* webpackChunkName: "component---src-pages-know-god-js" */),
  "component---src-pages-sermons-js": () => import("./../../../src/pages/sermons.js" /* webpackChunkName: "component---src-pages-sermons-js" */)
}

